.searchBarContainer {
  height: 64px;
  position: relative;
  border-bottom: solid 1px #0003;
}
.searchBarInput {
  height: 32px;
  width: 60%;
  border-radius: 24px 0 0 24px;
  outline: none;
  border: solid 1px #aaa;
  transform: translateX(8px);
  padding: 0px 20px;
}
