.infoCard {
  width: 100vw;
  height: 8vh;
  min-height: 64px;
  background: linear-gradient(to bottom, white, #efefef);
  box-shadow: 0px 2px 4px #0003;
}
.serviceNatureCircle {
  width: 48px;
  height: 48px;
  background: #3336;
  color: #444;
  border: solid 1px #aaa;
  border-radius: 50%;
  font-size: 1.5em;
}
.informationSection {
  width: 64%;
  max-width: 65vw;
  padding-left: 8px;
  align-items: flex-start;
}
.cardText {
  width: 51vw;
  text-align: left;
  padding-left: 4px;
  max-width: 51vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #444;
}
