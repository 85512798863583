.screenIndicator {
  z-index: 999;
  width: 100vw;
  height: var(--screenIndicatorHeight);
  min-height: var(--screenIndicatorHeight);
  max-height: var(--screenIndicatorHeight);
  background: var(--navBackgroundColor);
  font-size: 1.3em;
  padding-left: 8px;
  padding-right: 8px;
}
.tabContainer {
  padding-top: 1em;
}
.menuContainer {
  position: fixed;
  transition: all 0.3s cubic-bezier(0.07, 1.05, 0.44, 1.12);
  padding-right: 4.5%;
  top: 0;
  background: #fff;
  height: 100vh;
  width: 80vw;
  box-shadow: -4px 0px 4px #0002;
  z-index: 1;
}
.listItem {
  transition: all 0.3s;
}
.clickElseWhere {
  background: none;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 0;
  left: 0;
}
