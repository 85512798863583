* {
  font-size: 0.985em;
  --primaryTextColor: #444;
  --secondaryTextColor: #eee;
  --navBackgroundColor: #444;
  --primaryColor: #306ef4;
  --screenIndicatorHeight: 64px;
  --headerHeight: 48px;
  color: var(--primaryTextColor);
}
body {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
html {
  max-height: 100vh;
  max-width: 100vw;
  /* overflow: hidden; */
}
li {
  list-style: none;
}
ul {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  position: relative;
  width: 100vw;
  overflow: hidden;
  /* max-zoom: 0.1; */
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-row-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.full-size {
  width: 100%;
  height: 100%;
}
.pageContainer {
  position: relative;
  height: 100vh;
  width: 100vw;
  padding-right: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-50 {
  width: 50%;
}
.w-55 {
  width: 55%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}
.red {
  background: red;
}

.canvasOrImg {
  border: solid 1px #0004;
  height: 300px;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.textOverflow {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.primary {
  background: var(--primaryColor);
  color: var(--secondaryTextColor);
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inputStyle {
  border-radius: 0;
  border: none;
  outline: none;
  font-size: 0.985em;
  border-bottom: solid 1px #888;
  transition: all 0.3s;
}
.inputStyle:focus {
  border: none;
  outline: none;
  font-size: 1.05em;
  border-bottom: solid 1px #888;
  transition: all 0.3s;
}
.pointer {
  cursor: pointer;
}
