.sectionContainer {
  background: linear-gradient(to bottom, #efefef, #e0e0e0);
  border-radius: 4px;
  height: max-content;
}
.inputStyle {
  width: 100%;
}
.sectionHead {
  display: flex;
  justify-content: space-between;
  width: 98%;
  overflow-wrap: anywhere;
  text-align: left;
  border-bottom: solid 1px #aaa;
}
