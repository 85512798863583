.orderByIdCalendar {
  width: 40%;
  /* margin-top: -3px; */
}
.calendarPicker {
  width: 100%;
  margin-top: 8px;
}
.calendar {
  width: 100%;
  border-radius: 4px;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
  border-radius: 4px;
  cursor: pointer;
}
button.react-calendar__tile--active {
  background: var(--primaryColor);
}

button.react-calendar__tile--active > abbr {
  color: #fff !important;
}
.timePicker {
  height: 30px;
  border-radius: 4px;
  border-width: 1px;
  background: none;
  border-top: #888 1px solid;
  border-bottom: #888 1px solid;
  border-left: #888 1px solid;
  border-right: #888 1px solid;
}
