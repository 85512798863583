.actionContainer {
  min-width: 64px;
  max-width: 64px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.assignWorkerContainer {
  height: 32px;
  border-radius: 4px;
  background: #fff;
  transition: all 0.3s;
}
.assigned {
  min-width: fit-content;
  background: #306ef4dd;
  transition: all 0.3s;
}
.staffNumberContainer {
  min-width: 72px;
  max-width: 72px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.nameContainer {
  min-width: 72px;
  max-width: 72px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.mobileContainer {
  min-width: 76px;
  max-width: 76px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.callAndMobile {
  min-width: 108px;
  max-width: 108px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.roleContainer {
  min-width: max-content;
  max-width: max-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.checkboxContainer {
  min-width: 40px;
  max-width: 40px;
}
.areaContainer {
  min-width: 56px;
  max-width: 56px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.divisionContainer {
  min-width: 72px;
  max-width: 72px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.clientNameContainer {
  min-width: 120px;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.contactPersonContainer {
  min-width: 120px;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.telContainer {
  min-width: 80px;
  max-width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.addressContainer {
  min-width: 240px;
  max-width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tableHeaders {
  display: flex;
}
.closeButtonContainer {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
}
.chosen {
  color: #fff;
  transition: all 0.3s;
}
.searchInput {
  width: 70%;
  min-height: 40px;
  border-radius: 4px;
  border: solid 1px #444;
  margin-right: 4px;
  padding-left: 8px;
}
.searchInput::placeholder {
  padding-left: 8px;
}
