.starContainer {
  height: fit-content;
  width: 100%;
  background: linear-gradient(to bottom, #fcfcfc, #eee);
  border-radius: 4px;
  z-index: 1;
  position: relative;
}
.rightCol {
  position: relative;
  max-width: 424px;
  min-width: 60%;
}
.starCardText {
  width: max-content;
  font-size: 1.2em;
  white-space: nowrap;
  margin-right: 1em;
}
